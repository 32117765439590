<template>
<div class="main-div">
<div>
 <img alt="Vue logo" class="logo" src="./assets/statsify-logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</div>

</div>
 
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  overflow:hidden;
  padding:0;
  margin:0;
  height:100vh;
  width:100vw;
}
.main-div{
  display:flex;
  justify-content:center;
  align-items:center;
  background:#181818;
  height:100%;
  width:100%
}
.logo{
  width:200px;
}
</style>
